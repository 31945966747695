<template>
        <div 
            class="candidate"
            :class="{ red: networkcode === '0', orange: networkcode === '1', purple: networkcode === '2', green: networkcode === '3' }"
            v-if="name"
        >
            <div
                class="candidate-pic-container flex-center"
            >
                <img
                    v-if="image"
                    :src="image" 
                    width="100%" 
                    height="auto" 
                    alt="lead-profile-image"
                    class="candidate-pic"
                >
                <v-icon
                    v-else
                    large
                    style="border-radius: 50%; color: white; width: 100%;"
                    :class="{ red: networkcode === '0', orange: networkcode === '1', purple: networkcode === '2', green: networkcode === '3' }"
                >
                mdi-account-clock
                </v-icon>
            </div>
            {{name}}
            <div
                class="star-container"
                v-if="stars > 0"
            >
                <v-icon
                    v-for="(star, index) in stars"
                    :key="index"
                    class="m-0 p-0 star"
                    style="color: white;"
                >
                    mdi-star
                </v-icon>
            </div>
        </div>
</template>

<script>
export default {
    props: ['name', 'image', 'stars', 'networkcode'],
    data() {
        return {

        }
    },
    created() {
        
    }
}
</script>

<style scoped>
    .candidate {
        min-width: 90px;
        height: 30px;
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        margin: 15px 20px;
        padding-left: 15px;
        padding-right: 10px;
        animation-name: shake;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        position: relative;
    }
    @keyframes shake {
        0%   {left: 0px;}
        50%  {left: 5px;}
        100% {left: 0px}
    }
    .red {
        background-color: red;
    }
    .red > .candidate-pic-container {
        border: 4px solid red;
    }
    .purple {
        background-color: purple;
    }
    .purple > .candidate-pic-container {
        border: 4px solid purple;
    }
    .orange {
        background-color: orange;
    }
    .orange > .candidate-pic-container {
        border: 4px solid orange;
    }
    .green {
        background-color: green;
    }
    .green > .candidate-pic-container {
        border: 4px solid green;
    }
    .flex-center {
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .star-container {
        background-color: darkorchid;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 10px;
        border: 2px solid white;
        position: absolute;
        top: -17px;
        right: -10px;
    }
    .candidate-pic-container {
        border-radius: 50%;
        width: 44px;
        height: 44px;
        position: absolute;
        left: -30px;
        z-index: 500;
    }
    .candidate-pic {
        border-radius: 50%;
    }
</style>