<template>
  <div v-if="!fetchedOrLiveUser.stripe_id || fetchedOrLiveUser.stripe_id == '' || !isAnyOrdered">
    <b-dialog
      v-if="!fetchedOrLiveUser.stripe_id || fetchedOrLiveUser.stripe_id == ''"
      mainTitle="YOUR PROFILE IS NOT YET FILLED"
      subTitle="SO BUSINESS BOOSTER CAN NOT RUN IN LIVE MODE"
      :isSecondTitle=true
      secondTitle="OR YOU CAN SWITCH TO DEMO MODE"
      buttonIcon="mdi-pencil"
      buttonText="fill profile"
      buttonDestination='My Profile'
      :isSecondButton=true
      :isSecondButtonSwitch=true
      @dialogFeedback="switchDemo"
    />
    <b-dialog
      v-if="fetchedOrLiveUser.stripe_id && !isAnyOrdered"
      mainTitle="YOU DID START ANY SUBSCRIPTION YET"
      subTitle="SO BUSINESS BOOSTER CAN NOT RUN IN LIVE MODE"
      :isSecondTitle=true
      secondTitle="OR YOU CAN SWITCH TO DEMO MODE"
      buttonIcon="mdi-cash-multiple"
      buttonText="START A SUBSCRIPTION"
      buttonDestination='Buy Package'
      :isSecondButton=true
      :isSecondButtonSwitch=true
      @dialogFeedback="switchDemo"
    />

  </div>
  <v-container
    v-else
    id="dashboard"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <!-- {{ fetchedOrLiveUser.demo_list }} -->
      <!-- {{ fetchedOrLiveUser }}  -->
      <v-col cols="12">
        <base-material-card
          icon="mdi-progress-clock"
          :title="titleText"
        >
          <div>
            <span>Live operation - negotiation phases
              <div class="network-container">
                <span 
                  v-for="(network, index) in reverseNetworks" 
                  :key="index" 
                  class="network"
                  :style="{ backgroundColor: networkColors[network.id]}"
                >
                  {{network.name}}
                </span>
              </div>
            </span>
          </div>

          <v-row 
            v-for="(level, index) in sortedCandidates" 
            :key="index"
          >
            <sieve 
              :level='level' 
              :index='index'
              :sieveTitles="sieveTitles[index]"
            />
          </v-row>

        </base-material-card>
      </v-col>

      <v-col
        v-for="(list, listIndex) in fetchedOrLiveUser.lists"
        :key="listIndex"
        cols="12"
      >
        <delivery
          v-if="list.name_list.length < list.order_quantity"
          :list="list"
          @setEntryStatus="setEntryStatus"
      />
      </v-col>

<!--       <v-col cols="12">
        <base-material-card
          icon="mdi-earth"
          title="Global Sales by Top Locations"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="mt-10"
            >
              <v-simple-table class="ml-2">
                <tbody>
                  <tr
                    v-for="(sale, i) in sales"
                    :key="i"
                  >
                    <td>
                      <v-img
                        :src="sale.flag"
                        width="18"
                      />
                    </td>
                    <td v-text="sale.country" />
                    <td v-text="sale.salesInM" />
                    <td v-text="((sale.salesInM / totalSales) * 100).toFixed(2) + '%'" />
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-world-map
                :country-data="countryData"
                high-color="#838383"
                low-color="#BBBBBB"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="emailsSubscriptionChart.data"
          :options="emailsSubscriptionChart.options"
          :responsive-options="emailsSubscriptionChart.responsiveOptions"
          color="primary"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon color="info">
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Website Views
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Last Campaign Performance
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">updated 10 minutes ago</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="success"
          hover-reveal
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon color="info">
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Daily Sales
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            <v-icon
              color="green"
              small
            >
              mdi-arrow-up
            </v-icon>
            <span class="green--text">55%</span>&nbsp;
            increase in today's sales
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">updated 4 minutes ago</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        lg="4"
      >
        <base-material-chart-card
          :data="dataCompletedTasksChart.data"
          :options="dataCompletedTasksChart.options"
          hover-reveal
          color="info"
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="green"
                  icon
                  v-on="on"
                >
                  <v-icon color="info">
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h3 class="card-title font-weight-light mt-2 ml-2">
            Completed Tasks
          </h3>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            Last Last Campaign Performance
          </p>

          <template v-slot:actions>
            <v-icon
              class="mr-1"
              small
            >
              mdi-clock-outline
            </v-icon>
            <span class="caption grey--text font-weight-light">campaign sent 26 minutes ago</span>
          </template>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Followers"
          value="+245"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Website Visits"
          value="75.521"
          sub-icon="mdi-tag"
          sub-text="Tracked from Google Analytics"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Revenue"
          value="$ 34,245"
          sub-icon="mdi-calendar"
          sub-text="Last 24 Hours"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Bookings"
          value="184"
          sub-icon="mdi-alert"
          sub-icon-color="red"
          sub-text="Get More Space..."
        />
      </v-col>

      <v-col cols="12">
        <div
          class="font-weight-light mt-1"
          style="font-size: 25px"
        >
          Manage Listings
        </div>
      </v-col>

      <v-col
        sm="12"
        md="4"
      >
        <base-material-card
          color="transparent"
          image
          hover-reveal
        >
          <template v-slot:image>
            <v-img src="https://demos.creative-tim.com/vue-material-dashboard-pro/img/card-2.jpg" />
          </template>

          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="mx-1"
                  v-bind="attrs"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-view-split-vertical</v-icon>
                </v-btn>
              </template>

              <span>View</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  color="success"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon class="success--text">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>

              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  color="error"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon class="error--text">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>

              <span>Remove</span>
            </v-tooltip>
          </template>

          <v-card-title class="justify-center font-weight-light">
            Cozy 5 Stars Apartment
          </v-card-title>

          <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">
            The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the life in Barcelona.
          </v-card-text>

          <template v-slot:actions>
            <div class="display-2 font-weight-light grey--text">
              $899/night
            </div>

            <v-spacer />

            <span class="caption grey--text font-weight-light">
              <v-icon small>mdi-map-marker</v-icon>

              Barcelona, Spain
            </span>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        sm="12"
        md="4"
      >
        <base-material-card
          color="transparent"
          hover-reveal
          image
        >
          <template v-slot:image>
            <v-img src="https://demos.creative-tim.com/vue-material-dashboard-pro/img/card-3.jpg" />
          </template>

          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="mx-1"
                  v-bind="attrs"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-view-split-vertical</v-icon>
                </v-btn>
              </template>

              <span>View</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  color="success"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon class="success--text">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>

              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  color="error"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon class="error--text">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>

              <span>Remove</span>
            </v-tooltip>
          </template>

          <v-card-title class="justify-center font-weight-light">
            Office Studio
          </v-card-title>

          <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">
            The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the night life in London, UK.
          </v-card-text>

          <template v-slot:actions>
            <div class="display-2 font-weight-light grey--text">
              $1.119/night
            </div>

            <v-spacer />

            <span class="caption grey--text font-weight-light">
              <v-icon small>mdi-map-marker</v-icon>

              London, UK
            </span>
          </template>
        </base-material-card>
      </v-col>

      <v-col
        sm="12"
        md="4"
      >
        <base-material-card
          color="transparent"
          hover-reveal
          image
        >
          <template v-slot:image>
            <v-img src="https://demos.creative-tim.com/vue-material-dashboard-pro/img/card-1.jpg" />
          </template>

          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  class="mx-1"
                  v-bind="attrs"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-view-split-vertical</v-icon>
                </v-btn>
              </template>

              <span>View</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  color="success"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon class="success--text">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>

              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1"
                  color="error"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon class="error--text">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>

              <span>Remove</span>
            </v-tooltip>
          </template>

          <v-card-title class="justify-center font-weight-light">
            Beautiful Castle
          </v-card-title>

          <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">
            The place is close to Metro Station and bus stop just 2 min by walk and near to "Naviglio" where you can enjoy the main night life in Milan.
          </v-card-text>

          <template v-slot:actions>
            <div class="display-2 font-weight-light grey--text">
              $459/night
            </div>

            <v-spacer />

            <span class="caption grey--text font-weight-light">
              <v-icon small>mdi-map-marker</v-icon>

              Milan, Italy
            </span>
          </template>
        </base-material-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from "vuex"
import sieve from '@/views/dashboard/component/Sieve.vue'
import delivery from '@/views/dashboard/component/Delivery.vue'
import router from '@/router'
import BDialog from '@/views/dashboard/component/Dialog.vue'

export default {
  name: "DashboardDashboard",
  components: {
    sieve,
    delivery,
    BDialog
  },
  props: ['fetchedOrLiveUser', 'liveFeed'],
  data() {
    return {
      sieveTitles: [
        {
          main: 'INTERESTED', 
          sub: 'INTERESTED'
        },
        {
          main: 'EXPLAINING PRODUCT',
          sub: 'EXPLAINED'
        },
        {
          main: 'ANSWERING QUESTIONS',
          sub: 'ANSWERED'
        },
        {
          main: 'PRICE NEGOTIATION',
          sub: 'PRICE'
        },
        {
          main: 'OBJECTION HANDLING',
          sub: 'ACCEPTED'
        }
      ],
    };
  },

  computed: {
    titleText() {
      if (!this.fetchedOrLiveUser) {
        return 'processing data...'
      }
      let retValue = 'You do not have an active order.';
      this.fetchedOrLiveUser.lists.forEach( listElement => {
        if (Number(listElement.order_quantity) > listElement.name_list.length) {
          retValue = 'Your order is running...';
        }
      });
      return retValue;
    },

    isAnyFulfilled() {
      if (this.fetchedOrLiveUser.lists.length > 0) {
        this.fetchedOrLiveUser.lists.forEach( oneList => {
          if (oneList.name_list.length === oneList.order_quantity) {
            return false
          }
        })
      }
      return true
    },

    isAnyOrdered() {
      if (this.fetchedOrLiveUser.lists.length > 0) {
          return true
      }
      return false
    },

    isDemoMode() {
      return true
    },
    reverseNetworks() {
      if (!this.networks) { return [] }
      const reversedArr = this.networks
      return reversedArr.reverse()
    },

    sortedCandidates() {
      if (!this.liveFeed) {
        return [[0], [0], [0], [0], [0]]
      }
      if (this.liveFeed.length < 1) {
        return [[0], [0], [0], [0], [0]]
      }
      const sorted = [[0], [0], [0], [0], [0]]
      sorted[0] = this.liveFeed.filter(this.filterZeroStars)
      sorted[1] = this.liveFeed.filter(this.filterOneStars)
      sorted[2] = this.liveFeed.filter(this.filterTwoStars)
      sorted[3] = this.liveFeed.filter(this.filterThreeStars)
      sorted[4] = this.liveFeed.filter(this.filterFourStars)
      return sorted
    },

    levelZeroCandidates() {
      if (this.liveFeed.length > 0) {
        return this.liveFeed.filter(this.filterZeroStars)
      } else {
        return []
      }
    },
    levelOneCandidates() {
      if (this.liveFeed.length > 0) {
        return this.liveFeed.filter(this.filterOneStars)
      } else {
        return []
      }
    },
    levelTwoCandidates() {
      if (this.liveFeed.length > 0) {
        return this.liveFeed.filter(this.filterTwoStars)
      } else {
        return []
      }
    },
    levelThreeCandidates() {
      if (this.liveFeed.length > 0) {
        return this.liveFeed.filter(this.filterThreeStars)
      } else {
        return []
      }
    },
    levelFourCandidates() {
      if (this.liveFeed.length > 0) {
        return this.liveFeed.filter(this.filterFourStars)
      } else {
        return []
      }
    },
    totalSales() {
      return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
    },
    user() {
      return this.$store.getter.user
    },
    ...mapGetters(["networks", "networkColors"]),
  },

  created() {
    if (localStorage.getItem('stripesession')) {
      router.push('neworder')
    }
  },

  methods: {
    switchDemo(event) {
      console.log('dialog answered: ', event)
    },
    setEntryStatus(newStatus) {
      this.$store.dispatch('setEntryStatus', newStatus)
    },
    filterZeroStars(candidate, level) {
      return candidate.client_level === "0";
    },
    filterOneStars(candidate, level) {
      return candidate.client_level === "1";
    },
    filterTwoStars(candidate, level) {
      return candidate.client_level === "2";
    },
    filterThreeStars(candidate, level) {
      return candidate.client_level === "3";
    },
    filterFourStars(candidate, level) {
      return candidate.client_level === "4";
    },
    complete(index) {
      this.list[index] = !this.list[index];
    },
    handleGetUser() {
      this.$store.dispatch("getUser");
    },
  },
};
</script>

<style scoped>
  .full-height {
    min-height: 87vh;
  }
  .network-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
  }
  .network {
    background-repeat: no-repeat;
    padding: 0;
    margin: 3px;
    font-size: 10px;
    font-weight: 500;
    align-items: center;
    -webkit-box-align: center;
    color: #FFFF;
    letter-spacing: 0 !important;
    line-height: 1rem;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif !important;
    white-space: nowrap;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;

  }
  .red {
    background-color:red;
  }
  .orange {
    background-color: orange;
  }
  .purple {
    background-color: purple;
  }
  .green {
    background-color: green
  }
</style>
<style>
html {
  scroll-behavior: smooth;
}
</style>